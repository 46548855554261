
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-footer::v-deep {
    * {
        font-family: 'Segoe UI', '-apple-system', BlinkMacSystemFont, Roboto,
            'Helvetica Neue', Helvetica, Ubuntu, Arial, sans-serif !important;
    }
    .footer-group {
        color: #282b30;

        ul {
            list-style: none;
            padding-left: 0;

            &.two {
                columns: 2;
                column-gap: 3em;
            }

            li {
                padding-top: 0.7em;
            }
        }

        a {
            color: #282b30;
            transition: all 0.15s ease-out;
            text-decoration: none;

            &:hover {
                color: $primary-color;
            }

            &.router-link-active {
                color: $primary-color;
                pointer-events: none;
            }
        }
    }
}
